import React from 'react';
import './PortfolioItem.scss';
// import ArrowUpRightIcon from 'media/icons/arrow-up-right.svg';
import { ArrowUpRightIcon } from '@heroicons/react/20/solid';


interface PortfolioItemProps {
  title: string;
  img: string | any;
  description: string;
  link: string;
  tech: string[];
}

const PortfolioItem = ({title, img, description, link, tech}: PortfolioItemProps) => {

  return (
    <div className='portfolio-item-outer'>
      <a 
        className='link-to-project'
        href={link}
        target='_blank' 
      >
        <img 
          src={img} 
          alt="image of project landing page" 
          className='landing-page-img'
        />
        <div className='title-and-link-container'>
          <h4 className='dark'>{title}</h4>
          <div className='arrow-icon-container'>
            <ArrowUpRightIcon className='arrow-icon'/>
          </div>
        </div>
      </a>
      <p className='medium dark'>{description}</p>
      <div className='tech-tags'>
        {
          tech.map((item, index) => {
            return (
              <TechTag 
                name={item} 
                key={index}
              />
            )
          })
        }
      </div>
    </div>
  );
}

export default PortfolioItem;

interface TechTagProps {
  name: string;
}

const TechTag = ({ name }: TechTagProps) => {
  return (
    <div className='tech-tag'>
      {name}
    </div>
  )
}