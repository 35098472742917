import React from 'react';
import './IconButton.scss';


interface IconButtonProps {
  icon: any;
  onClick?: () => void;
  className?: string;
}

const IconButton = ({icon, onClick, className}: IconButtonProps) => {
  return (
    <button 
      className={`${className} icon-button`}
      onClick={onClick}
    >
      {icon}
    </button>
  );
}

export default IconButton;