import NavBar from 'components/NavBar';
import AboutSection from 'components/sections/AboutSection';
import ContactSection from 'components/sections/ContactSection';
import FooterSection from 'components/sections/FooterSection';
import HeroSection from 'components/sections/HeroSection';
import PortfolioSection from 'components/sections/PortfolioSection';
import SkillsSection from 'components/sections/SkillsSection';
import TestimonialsSection from 'components/sections/TestimonialsSection';
import React, { useState } from 'react';
import { RocketLaunchIcon } from '@heroicons/react/24/solid';
import BackToTopButton from 'components/BackToTopButton';

const App = () => {
  return (
    <div>
      <NavBar />
      <HeroSection />
      <AboutSection />
      <SkillsSection />
      <PortfolioSection />
      <TestimonialsSection />
      <ContactSection />
      <FooterSection /> 
      <BackToTopButton />
    </div>
  );
}

export default App;

