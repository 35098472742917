import React from 'react';
import './SkillsSection.scss';
import TechCard from 'components/cards/TechCard';
import FigmaLogo from 'media/logos/figma.png';
import ReactLogo from 'media/logos/react.png';
import JsLogo from 'media/logos/javascript.png';
import CssLogo from 'media/logos/css.png';
import TypescriptLogo from 'media/logos/typescript.png';
import PhpLogo from 'media/logos/php.png';
import HtmlLogo from 'media/logos/html.png';
import SassLogo from 'media/logos/sass.png';
import WordpressLogo from 'media/logos/wordpress.png';
import SoftSkillsIll from 'media/illustrations/skills.png';

const techSkills = [{
  name: 'React.js',
  logo: ReactLogo
},
{
  name: 'Figma',
  logo: FigmaLogo
},
{
  name: 'JavaScript',
  logo: JsLogo
},
{
  name: 'Typescript',
  logo: TypescriptLogo
},
{
  name: 'PHP',
  logo: PhpLogo
},
{
  name: 'HTML',
  logo: HtmlLogo
},
{
  name: 'CSS',
  logo: CssLogo
},
{
  name: 'Sass',
  logo: SassLogo
},
{
  name: 'Wordpress',
  logo: WordpressLogo
} 
];

const techCards: React.JSX.Element[] = [];

for (let i = 0; i < techSkills.length; i++) {
  techCards.push(
    <TechCard
      title={techSkills[i].name}
      img={techSkills[i].logo}
      key={i}
    />
  )
}


const SkillsSection = () => {
  return (
    <section id="skills">
      <div className="content-wrapper">
        <div className='skills-intro'> 
          <h2 className='dark'>Skills</h2>
        </div>
        <div className='skills-inner'>
          <div className='tech-skills'>
            <h4 className='dark pre-header-line'>Technical skills</h4>
            <div className='tech-grid'>
              {techCards}
            </div>
          </div>
          <div className='soft-skills'>
            <h4 className='dark pre-header-line'>Soft skills</h4>
            <p className='large dark'>
              With endless <strong>creativity</strong> and strong <strong>problem-solving</strong> skills, I bring fresh ideas to your project. 
              This, together with being a <strong>fast learner</strong> and quick at adopting new technologies, ensures an <strong>effective</strong> collaboration.
            </p>
            <div className='languages-outer'>
              <div className='soft-skills-ill-container'>
                <img 
                  src={SoftSkillsIll} 
                  alt="soft skills illustration" 
                />
              </div>
              <div className='language-card'>
                <h5 className='language-card-title'>Languages I speak</h5>
                <h5>English</h5>
                <p className='large'>Native</p>
                <h5>Dutch</h5>
                <p className='large'>Mother Tongue</p>
                <h5>Swedish</h5>
                <p className='large'>Lagom</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SkillsSection;