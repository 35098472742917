import React, { useState } from 'react';
import './ContactSection.scss';
import ContactIll from 'media/illustrations/contact.png';
import Button from 'components/Button';
import { EnvelopeIcon } from '@heroicons/react/24/solid';
import { PhoneIcon } from '@heroicons/react/24/solid';
import { MapPinIcon } from '@heroicons/react/24/solid';


const ContactSection = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const sendForm = async (e: any) => {
   e.preventDefault();

    try {
      const response = await fetch('https://elissasportfolio.com/send-email.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert('Message sent successfully!');
        setFormData({ name: '', email: '', phone: '', message: '' });
      } else {
        alert('Failed to send message.');
      }
    } catch (error) {
      alert('An error occurred while sending the message.');
    }
  };

  return (
    <section>
      <div className="content-wrapper">
        <div className='contact-inner'>
          <div className='col-2 contact-info-outer'>
            <h3>Get in touch</h3>
            <p className='medium dark intro-text'>
              Whether you have an idea for a project or just want to chat, feel free to shoot me an email!
            </p>
            <div className='contact-info'>
              <div className='col-2'>
                <div className='contact-mode'>
                  <PhoneIcon />
                  <p className='medium dark'>+46 763 27 26 35 </p>
                </div>
                <div className='contact-mode'>
                  <EnvelopeIcon />
                  <p className='medium dark'>sayhello@elissasportfolio.com</p>
                </div>
                <div className='contact-mode'>
                  <MapPinIcon />
                  <p className='medium dark'>Stockholm </p>
                </div>
              </div>
              <div className='col-2 contact-ill-outer'>
                <img src={ContactIll} alt="contact illustration" />
              </div>
            </div>
          </div>
          <div className='col-2'>
            <form  
              className='contact-form'
              onSubmit={sendForm}
            >
              <input 
                type="text" 
                name="name"
                placeholder='Name' 
                value={formData.name}
                onChange={handleChange}
                required
              />
              <input 
                type="text" 
                name="email"
                placeholder='Email' 
                value={formData.email}
                onChange={handleChange}
                required
              />
              <input 
                type="text" 
                name="phone"
                placeholder='Phone number' 
                value={formData.phone}
                onChange={handleChange}
                required
              />
              <textarea 
                placeholder='Your message' 
                name="message"
                rows={7}
                value={formData.message}
                onChange={handleChange}
                required
              />
              <Button 
                title='Send message'
                color='dark'
                type='submit'
              />
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactSection;