import React from 'react';
import './ServiceCard.scss';


interface ServiceCardProps {
  title: string;
  description: string;
  img: string;
}

const ServiceCard = ({title, description, img}: ServiceCardProps) => {

  return (
    <div className='service-card-outer'>
      <img src={img} alt="" className='service-ill' />
      <h5>{title}</h5>
      <p className='dark small'>{description}</p>
    </div>
  );
}

export default ServiceCard;