import React, { useState } from 'react';
import './TestimonialSection.scss';
import AntonImg from 'media/images/anton.png';
import ErikImg from 'media/images/erik.jpeg';
import KarolImg from 'media/images/karol.jpeg';
import IconButton from 'components/IconButton';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import { ChevronRightIcon } from '@heroicons/react/24/solid';

const testimonialData = [
  {
    name: 'Karol Stefanski',
    img: KarolImg,
    company: 'Design consultant and educator',
    text: 'Elissa isn\'t your average front-end developer – she\'s basically a UI designer\'s secret weapon. Her design chops are on point, and she can translate even the most ambitious mockup into an experience that\'s intuitive, beautiful, and bug-free. Pixel perfect and user-friendly? That\'s Elissa\'s middle name (well, not really, but you get the idea). Plus, she has a killer eye for detail – nothing slips past her!'
  },
  {
    name: 'Anton Netterwall',
    img: AntonImg,
    company: 'Senior software engineer',
    text: 'Elissa is a tremendous asset in any team. Her endless energy, creativity and user centric approach will leave both your code, UI and product in a better state. She also has the ability to notice and take ownership of the things that otherwise fall between the cracks, making sure not only herself, but her entire team, can operate with full efficiency. Add to that the capacity to quickly assimilate new technology and skills, she has been playing a key role in our company from day one. Simply one of those people you hope to work with again in the future.'
  },
  {
    name: 'Erik Rothoff',
    img: ErikImg,
    company: 'CEO at Feeder',
    text: 'Elissa was an incredible asset to our team, showing incredible breadth of skills and a keen sense for building great products. As a fast learner, she quickly became fluent in our complex tech stack. She could single-handedly take projects from ideation through to design and to code, and folloing through with listening to customers and iterating. Working with Elissa on projects was always a pleasure, and I so enjoyed our time together. I can’t recommend her enough!'
  },
]


const TestimonialsSection = () => {
  const [current, setCurrent] = useState(0);
  const length = testimonialData.length;

  const handleNext = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  }

  const handlePrev = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  }

  return (
    <section className='dark'>
      <div className='testimonial-content-wrapper'>
        <div className='testimonials-slider-container'>
          <div 
            className='click-area'
            onClick={handlePrev}
          >
            <IconButton 
              icon={<ChevronLeftIcon className='large' />}
            />
          </div>
          <div className='testimonials-inner'>
            <h3>Testimonials</h3>
            <div className='testimonials-container'>
              {
                testimonialData.map((testimonial, index) => {
                  
                  return (
                    index === current && 
                    <div key={index} className='testimonial-box'>
                      <p>{testimonial.text}</p>
                      <div className='testimonal-author'>
                        {testimonial.img && <img src={testimonial.img} alt="testimonial autohor" />}
                        <div className='testimonial-author-inner'>
                          <div className='name'>{testimonial.name}</div>
                          <span>/</span>
                          <div className='company'>{testimonial.company}</div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
          <div 
            className='click-area'
            onClick={handleNext}
          >
            <IconButton 
              icon={<ChevronRightIcon className='large' />}
              className='yellow-icon-button'
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default TestimonialsSection;