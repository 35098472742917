import React, { useCallback, useState } from 'react';
import './NavBar.scss';
import Button from './Button';
import LinkButton from './LinkButton';
import CV from 'media/images/elissa.pdf';

const NavBar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleHamburgerClick = () => {
    mobileMenuOpen ? setMobileMenuOpen(false) : setMobileMenuOpen(true);
    document.body.classList.toggle("no-scroll");
  }

  const handleNavClick = () => {
    if (mobileMenuOpen) {
      setMobileMenuOpen(false);
      document.body.classList.toggle("no-scroll");
    }
  }

  return (
    <header>
      <div className='content-wrapper inner-container'>
        <a 
          className='header-logo'
          href='/'
        >
          Elissa
        </a>
        <nav 
          className={`header-nav ${mobileMenuOpen && 'open'}`}
          onClick={handleNavClick}
        >
          <ul className='nav-outer'>
            <li key={1} className='nav-item'>
              <LinkButton title='About me' type='light' href='#about'/>
            </li>
            <li key={2} className='nav-item'>
              <LinkButton title='Skills' type='light' href='#skills'/>
            </li>
            <li key={3} className='nav-item'>
              <LinkButton title='Projects' type='light' href='#portfolio'/>
            </li>
            <li key={4} className='nav-item'>
              <a 
                className='link-button'
                href={CV}
                target='_blank'
              >
                CV
              </a>
            </li>
            <li key={5} className='nav-item'>
              <a href='mailto:sayhello@elissasportfolio.com'>
                <Button 
                  title='Contact me'
                  color='light'
                />
              </a>
            </li>
          </ul>
        </nav>
        <div 
          className={`hamburger-outer ${mobileMenuOpen && 'open'}`}
          onClick={handleHamburgerClick}
        >
          <span className='lines'>
            <span></span>
          </span>
        </div>
      </div>
    </header>
  );
}

export default NavBar;