import Button from 'components/Button';
import React from 'react';
import './HeroSection.scss';
import 'index.scss';
import webcreator from 'media/illustrations/programmer-girl2.png';

const HeroSection = () => {
  return (
    <section id="hero">
      <div className="content-wrapper">
        <div className="hero-inner">
          <div className='hero-text'>
            <h1>Hi, I’m Elissa and I’m a <strong>front-end developer</strong></h1>
            <p className='dark large'>
              Welcome to my portfolio! I bring creative ideas to life on the web by blending technology and design.
              Feel free to scroll around, and get in touch if you're interested in my work - or just want to chat about all things front-end ✨
            </p>
            <div className='button-container'>
              <a href='mailto:sayhello@elissasportfolio.com'>
                <Button 
                  title='Get in touch'
                  color='light'
                />
              </a>
              <a href="#portfolio">
                <Button 
                  title='Projects'
                  color='dark'
                />
              </a>
            </div>
          </div>
          <div className='hero-ill'>
            <img 
              src={webcreator} 
              alt="illusration of web creator"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroSection;