import React from 'react';
import './PortfolioSection.scss';
import AnnapurnaImg from 'media/project-shots/annapurna.png';
import FeederImg from 'media/project-shots/feeder.png';
import ArtworkImg from 'media/project-shots/gunilla-artwork.png';
import IreturImg from 'media/project-shots/iretur.png';
import KiwanoGardenImg from 'media/project-shots/kiwano-garden.png';
import MareduImg from 'media/project-shots/maredu.png';
import PortfolioItem from 'components/PortfolioItem';


const portfolioItems = [
  {
    id: 'feeder',
    name: 'Feeder',
    description: 'Feeder is a SaaS company where I worked full time for 2 years as the lead front-end developer.',
    link: 'https://feeder.co/',
    tech: ['Ember.js', 'React.js', 'Typescript', 'Sass', 'Figma'],
    img: FeederImg
  },
  {
    id: 'annapurna',
    name: 'Annapurna',
    description: 'I both designed and developed this website with a creative touch for a consultancy agency.',
    link: 'https://www.annapurna.se/',
    tech: ['HTML', 'CSS', 'JavaScript', 'PHP', 'Figma'],
    img: AnnapurnaImg
  },
  {
    id: 'i-retur',
    name: 'I Retur',
    description: 'For I Retur, I created a colourful website and logo to match this creative business owner and help her launch her part-time clothes altering business in style.',
    link: 'https://iretur.se/',
    tech: ['Wordpress', 'JavaScript', 'PHP', 'Figma'],
    img: IreturImg
  },
  {
    id: 'maredu',
    name: 'Maredu',
    description: 'I worked with Dan from Maredu to create a website that features online sailing courses.',
    link: 'https://maredu.se/',
    tech: ['Wordpress', 'JavaScript', 'PHP', 'Figma'],
    img: MareduImg
  },
  {
    id: 'gunilla-artwork',
    name: 'Gunilla Artwork',
    description: 'I designed and developed a portfolio website, and logo, for a talented mixed media artist to showcase and sell her work.',
    link: 'https://gunillaartwork.se/',
    tech: ['Wordpress', 'JavaScript', 'PHP', 'Figma'],
    img: ArtworkImg
  },
  {
    id: 'kiwano-garden',
    name: 'Kiwano Garden',
    description: 'I worked with Kiwano Garden to create landing pages with contact forms for a marketing campaign.',
    link: 'https://www.kiwanogarden.se/hembesok/',
    tech: ['Wordpress', 'JavaScript', 'PHP'],
    img: KiwanoGardenImg
  },
];


const PortfolioSection = () => {
  return (
    <div>
      <section id="portfolio" className='dark'> 
        <div className="content-wrapper">
          <div className='portfolio-intro'> 
              <h2>Some of my work</h2>
              <p className='medium'>
                A collection of projects I've contributed to. 
                From sleek and simple landing pages to intricate web applications, each project showcases a blend of creativity and technical skill. 
                Most of my recent work is the result of collaborative efforts with some truly talented individuals.
              </p>
          </div>
        </div>
      </section>
      <section className='portfolio-content'>
        <div className='content-wrapper'>
          <div className='portfolio-grid'>
            {
              portfolioItems.map(item => {
                return (
                  <PortfolioItem 
                    title={item.name}
                    description={item.description}
                    img={item.img}
                    tech={item.tech}
                    link={item.link}
                    key={item.id}
                  />
                )
              })
            }
          </div>
        </div>
      </section>
    </div>
  )
}

export default PortfolioSection;