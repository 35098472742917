import React from 'react';
import './TechCard.scss';


interface TechCardProps {
  title: string;
  img: string;
}

const TechCard = ({title, img}: TechCardProps) => {

  return (
    <div className='tech-card-outer'>
      <img src={img} alt="" className='tech-ill' />
      <p className='dark medium'>{title}</p>
    </div>
  );
}

export default TechCard;