import React from 'react';
import './FooterSection.scss';
import LinkButton from 'components/LinkButton';
import { ReactComponent as LinkedinIcon } from 'media/icons/linkedin.svg';
import { EnvelopeIcon } from '@heroicons/react/24/solid';

const FooterSection = () => {
  return (
    <section className='dark'>
      <div className='content-wrapper'>
        <footer>
          <div className='footer-logo'>Elissa</div>
          <nav>
          <ul className='nav-outer'>
            <li key={1} className='nav-item'>
              <LinkButton 
                title='Home' 
                type='dark' 
                href='/'
              />
            </li>
            <li key={2} className='nav-item'>
              <LinkButton 
                title='About me' 
                type='dark' 
                href='#about'
              />
            </li>
            <li key={3} className='nav-item'>
              <LinkButton 
                title='Skills' 
                type='dark' 
                href='#skills'
              />
            </li>
            <li key={4} className='nav-item'>
              <LinkButton 
                title='Portfolio' 
                type='dark' 
                href='#portfolio'
              />
            </li>
            <li key={5} className='nav-item'>
              <LinkButton 
                title='Contact' 
                type='dark' 
                href='mailto:sayhello@elissasportfolio.com'
              />
            </li>
          </ul>
        </nav>
        <div className='solials-outer'> 
          <a href='https://www.linkedin.com/in/elissa-tsekouras-a7892918a/' className='social-icon' target='_blank'>
            <LinkedinIcon />
          </a>
          <a href='mailto:sayhello@elissasportfolio.com' className='social-icon'>
            <EnvelopeIcon  className='mail'/>
          </a>
        </div>
        <div className='copywrite-box'>
          <p className='medium light'>Copyright @2024</p>
          <p className='small light'>
            This website is created with ❤️ design by 
            <span> <a href="https://www.behance.net/agotabalnione?fbclid=IwAR32ssO151DUweJ7OFITL_QTqYreIJXW454Olbt8Fui7k7QCkwVs9Mq0Lh8" target='_blank'>Agota</a> </span> 
            and built by me
          </p>
        </div>
        </footer>
      </div>
    </section>
  )
}

export default FooterSection;