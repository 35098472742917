import React from 'react';
import './Button.scss';


interface ButtonProps {
  title: string;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  color: 'light' | 'dark';
  type?: 'button' | 'reset' | 'submit';
}

const Button = ({title, color = 'light', className, disabled, onClick, type = 'button'}: ButtonProps) => {
  const didClick = () => {
    if (disabled) return;
    if (onClick) return onClick();
    return;
  }

  return (
    <button 
      className={`${color} ${className} ${disabled} button`}
      onClick={didClick}
      type={type}
    >
      {title}
    </button>
  );
}

export default Button;