import React from 'react';
import './LinkButton.scss';


interface LinkButtonProps {
  title: string;
  className?: string;
  disabled?: boolean;
  href?: string;
  type: 'light' | 'dark';
}

const LinkButton = ({title, type = 'light', className, disabled, href}: LinkButtonProps) => {

  return (
    <a 
      className={`${type} ${className} ${disabled} link-button`}
      href={href}
    >
      {title}
    </a>
  );
}

export default LinkButton;