import React, { useState } from 'react';
import { RocketLaunchIcon } from '@heroicons/react/24/solid';
import IconButton from 'components/IconButton';
import './BackToTopButton.scss';


const BackToTopButton = () => {
  const [scrollButtonVisible, setScrollButtonVisible] = useState(false);

  const toggleScrollbuttonVisible = () => {
    const scrolled = document.documentElement.scrollTop; 
    if (scrolled > 300){ 
      setScrollButtonVisible(true) 
    }  
    else if (scrolled <= 300){ 
      setScrollButtonVisible(false) 
    } 
  }

  const backToTop = () => {
    window.scrollTo({ 
      top: 0,  
      behavior: 'smooth'
    });
  }

  window.addEventListener('scroll', toggleScrollbuttonVisible);

  return (
    <div 
      className={`back-to-top-button ${scrollButtonVisible && 'visible'}`}
      onClick={backToTop}
    >
      <IconButton icon={<RocketLaunchIcon className='small'/>}/>
    </div>
  );
}

export default BackToTopButton;