import React from 'react';
import './AboutSection.scss';
import me from 'media/images/elissa.png';
import ServiceCard from 'components/cards/ServiceCard';
import WebDeveloper from 'media/illustrations/web-developer.png';
import WebDesigner from 'media/illustrations/web-design.png';
import SoftwareDev from 'media/illustrations/software-developer.png';
import UserExperience from 'media/illustrations/ui-ux.png';

const AboutSection = () => {
  return (
    <section id="about" className='dark'>
      <div className="content-wrapper">
        <div className='about-inner'> 
          <h2>About me</h2>
          <div className='about-elissa'>
            <div className='image-container'>
              <img src={me} alt="Picture of elissa drinking coffee" />
            </div>
            <p className='medium'>
              As a front-end developer, I love bridging the gap between engineering and design 
              - combining my technical knowledge with my keen eye for design to create a beautiful product. 
              I always aim to build applications that are scalable and efficient while providing engaging, 
              pixel-perfect user experiences.
              <br />
              <br />
              When I'm not behind my computer I'm usually out running, exploring remote destinations, or crossing off another item from my bucket list.
            </p>
          </div>
          {/* <div className='info-container'>
            <div className='info-card'>
              <div className='number'>6+</div>
              <h5>Projects done</h5>
            </div>
            <div className='info-card'>
              <div className='number'>4+</div>
              <h5>Years of Experience</h5>
            </div>
            <div className='info-card'>
              <div className='number'>100%</div>
              <h5>Clients Satisfaction</h5>
            </div>
          </div> */}
          <h4>What I do</h4>
          <p className='medium services'>
            I develop and design things for the web - from simple landing pages to full scale web applications. 
          </p>
          <div className='service-container'>
            <ServiceCard 
              title='Web Development'
              img={WebDeveloper}
              description='I create custom websites that bring your unique vision to life and engage your audience.'
            />
            <ServiceCard 
              title='Software Development'
              img={SoftwareDev}
              description='Using modern front-end frameworks, I take pride in delivering outstanding user experience written in clean, elegant code.'
            />
            <ServiceCard 
              title='User Experience'
              img={UserExperience}
              description='Whether I code a simple landing page or develop a complex new feature for an app, the end user is always my guiding star.'
            />
            <ServiceCard 
              title='Web Design'
              img={WebDesigner}
              description='A keen eye for design is my super power to create exceptional online experiences.'
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutSection;